import Slider from '../Slider/Slider';

function Card(props) {

  function formPreviews(fileName, lastIndex, directory) {
    const previews = ['https://examen-technolab.ru/images/cards/' + directory + '/' + fileName];
    for (let i = 1; i <= lastIndex; i++) {
      const pointIndex = previews[0].lastIndexOf('.');
      previews.push(previews[0].slice(0, pointIndex) + `-${i}` + previews[0].slice(pointIndex, previews[0].length));
    }

    return previews;
  }

  const card = props.card;


  // формируем массив превьюшек
  const previews = formPreviews(card.preview, card.lastPreview, card.product);


  //console.log(props.img[0].replace("-preview", ""));

  // const preview = [];
  // props.img.map((item, index) => {
  //   preview[index] = item.replace("-preview", "")
  // })


  return (
    <div className={`card card_type_${props.type} card_level_${card.type}`}>
      <Slider setPopupIsVIsible={props.setPopupIsVIsible} setPopupContent={props.setPopupContent} sliderClass="card__slider" title={card.title} img={previews} />
      <h2 className="text text_uppercase card__title">{card.title}</h2>
      <p className="text text_uppercase card__article">Артикул: {card.article}</p>
      <p className={`text text_uppercase card__price highlighted-text highlighted-text_level_${card.type}`}>Цена: {card.price} руб.</p>
      {props.children}
    </div>
  );
}
export default Card;
