class Api {
  constructor({ baseUrl, headers }) {
    this._baseUrl = baseUrl;
    this._headers = headers;
  }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject(`Ошибка: ${res.status}`);
  }

  getCard(id) {
    return fetch(`${this._baseUrl}/cards/${id}`, {
      method: 'GET',
      credentials: 'include',
      headers: this._headers,
    })
      .then(this._checkResponse)
  }

  getCardInfo(id, tab) {
    return fetch(`${this._baseUrl}/cards/${id}/${tab}`, {
      method: 'GET',
      credentials: 'include',
      headers: this._headers,
    })
      .then(this._checkResponse)
  }

  getInitialCards() {
    return fetch(`${this._baseUrl}/cards`, {
      method: 'GET',
      credentials: 'include',
      headers: this._headers,
    })
      .then(this._checkResponse)
  }

  getData(param) {
    return fetch(`${this._baseUrl}/${param}`, {
      method: 'GET',
      credentials: 'include',
      headers: this._headers,
    })
      .then(this._checkResponse)
  }

  getList(list, setList, name) {
    if (!list.length) {
      this.getData(name)
        .then((newList) => {
          console.log(newList);
          setList(newList);
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }

}
const api = new Api({
  baseUrl: 'https://api.examen-technolab.ru/',
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api
