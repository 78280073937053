import React from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import { useState } from 'react';
import { useRef } from "react";
import { useEffect } from 'react';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Main from '../Main/Main';
import Catalog from '../Catalog/Catalog';
import Product from '../Product/Product';
import Contacts from '../Contacts/Contacts';
import About from '../About/About';
import Events from '../Events/Events';
import EventPage from '../EventPage/EventPage';
import Triangle from '../Triangle/Triangle';
import Popup from '../Popup/Popup';
import Files from '../Files/Files';
import FilesWithFilter from '../FilesWithFilter/FilesWithFilter';
import Education from '../Education/Education';
import UpButton from '../UpButton/UpButton';

import filterForManuals from '../../utils/filterForManuals';



function App() {

  const [popupIsVisibel, setPopupIsVIsible] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [cards, setCards] = useState([]);
  const [education, setEducation] = useState([]);
  const [manuals, setManuals] = useState([]);
  const [partners, setPartners] = useState([]);
  const [downloads, setDownloads] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [about, setAbout] = useState([]);
  //const [upBtnClass, setUpBtnClass] = useState('hidden');


  const [scrollY, setScrollY] = useState(false);

  function handleScroll() {
    setScrollY(window.scrollY);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, [])


  function closePopup() {
    setPopupIsVIsible(false);
    setPopupContent('');
  }

  // function onUpButtonClick() {
  //   window.scrollTo(0, 0);
  // }

  // function handleScroll() {
  //   setUpBtnClass((window.scrollY < window.innerHeight / 2) ? 'hidden' : '');
  //   animateMain(firstMainSectionRef, .5, setFirstMainSectionClass);
  //   animateMain(mainMediaRef, 1.5, setMainMediaClass);
  // }

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  // }, [])




  return (
    <div className="background">
      <div className="page">
        <BrowserRouter>
          <Header />
          <Route exact path="/">
            <Redirect to="/main" />
          </Route>
          <Route path="/main">
            <Main scrollY={scrollY} />
          </Route>
          <Route exact path="/catalog">
            <Catalog cards={cards} setCards={setCards} setPopupIsVIsible={setPopupIsVIsible} setPopupContent={setPopupContent} />
          </Route>
          <Route path={`/catalog/:id`}>
            <Product cards={cards} setPopupIsVIsible={setPopupIsVIsible} setPopupContent={setPopupContent} />
          </Route>
          <Route exact path="/education">
            <Education list={education} setList={setEducation} title="Обучение" />
          </Route>
          <Route path="/education/manuals">
            <FilesWithFilter options={filterForManuals} list={manuals} setList={setManuals} title="Пособия" />
          </Route>
          <Route path="/downloads">
            <Files list={downloads} setList={setDownloads} isSimple={true} title="Загрузки" />
          </Route>
          <Route path="/contacts">
            <Contacts list={contacts} setList={setContacts} />
          </Route>
          <Route path="/about">
            <About list={about} setList={setAbout} />
          </Route>
          <Route path="/partners">
            <Files list={partners} setList={setPartners} title="Партнерам" />
          </Route>
          <Route exact path="/events">
            <Events />
          </Route>
          <Route path={`/events/:eventUrl`}>
            <EventPage />
          </Route>
          {/* <UpButton onButtonClick={onUpButtonClick} upBtnClass={upBtnClass} /> */}
          <Triangle />
          <Popup closePopup={closePopup} isVisible={popupIsVisibel} children={popupContent} />
          <Footer />
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;

