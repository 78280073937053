import React, { useState } from 'react';
import { Switch, useRouteMatch, useParams, Route } from 'react-router-dom';
import CardWithMenu from '../CardWithMenu/CardWithMenu';
import CardWithText from '../CardWithText/CardWithText';

import api from '../../utils/Api';

function Product(props) {
  let { id } = useParams();
  const [state, setState] = useState(false);
  const [card, setCard] = useState({});

  React.useEffect(() => {
    if (props.cards.length) {
      setCard(props.cards.find(card => card.id === id.toString()));
      setState(true);
    } else {
      api.getCard(id)
        .then((cardData) => {
          setCard(cardData[0]);
          setState(true);
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }, []);

  const { url, path } = useRouteMatch();

  const kit = [];
  const specifications = [];


  return (
    <section className="section product">
      {state ?
        <><h1 className="hidden"> {card.title} </h1>
          <CardWithMenu setPopupIsVIsible={props.setPopupIsVIsible} setPopupContent={props.setPopupContent} url={url} card={card} />
          <Switch>
            <Route exact path={`${path}/:tab`}>
              <CardWithText cardId={id} list={kit} level={card.type} />
            </Route>
            {/* <Route path={`${path}/specifications`}>
              <CardWithText list={specifications} level={card.type} />
            </Route> */}
            {/* <Route path={`${path}/downloads`}>
              <CardWithText list={[{
                title: "Раздел дополняется", list: [],
                note: '',
              }]} level={card.type} />
            </Route> */}
          </Switch></> : <>Loading</>}
    </section>
  );
}
export default Product;