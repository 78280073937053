import React, { useState } from 'react';

import TileWithScroll from '../TileWithScroll/TileWithScroll';
import Description from "../Description/Description";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import api from '../../utils/Api';

function CardWithText(props) {
  let { tab } = useParams();

  const [list, setList] = useState([]);

  React.useEffect(() => {
    api.getCardInfo(props.cardId, tab)
      .then((data) => {
        const newList = [];
        data.forEach(element => {
          newList.push({
            title: element.title,
            list: element.list == null ? [] : element.list.split("','"),
            note: element.note,
          })
        });
        setList(newList);
      })
      .catch((err) => {
        console.log(err);
      })

  }, [tab]);

  return (
    <TileWithScroll level={props.level} tileClass="card-with-text">
      {
        <>
          {
            list.map((item, index) => {
              return (
                <Description key={props.level + 'Description' + index.toString()} level={props.level} title={item.title} list={item.list} note={item.note} />
              )
            })
          }
        </>
      }
    </TileWithScroll >
  );
}
export default CardWithText;