import React from 'react';
import CardWithBtn from '../CardWithBtn/CardWithBtn';

import api from '../../utils/Api';

function Catalog(props) {

  React.useEffect(() => {
    if (!props.cards.length) {
      api.getInitialCards()
        .then((cardsData) => {
          props.setCards(cardsData);
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }, []);

  return (
    <main className="section catalog">
      <h1 className="hidden"> Каталог </h1>
      <ul className="catalog__list">
        {
          props.cards.map((card, index) => {
            return (
              <CardWithBtn setPopupIsVIsible={props.setPopupIsVIsible} setPopupContent={props.setPopupContent} key={'Card' + index.toString()} card={card} />
            )
          })
        }
      </ul>
    </main>
  );
}
export default Catalog;