import { useState, useEffect } from "react";

import Filter from "../Filter/Filter";
import Files from "../Files/Files";

import api from "../../utils/Api";

function FilesWithFilter(props) {

  useEffect(() => {
    if (!props.list.length) {
      const pathname = window.location.pathname.split('/');
      const param = pathname[pathname.length - 1];
      api.getData(param)
        .then((list) => {
          props.setList(list);
          setFilesList(list);
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }, []);

  const [filesList, setFilesList] = useState(props.list);

  return (
    <Files list={filesList} title={props.title} isSimple={false} >
      <Filter list={props.list} setList={setFilesList} options={props.options} />
    </Files>
  );
}
export default FilesWithFilter;